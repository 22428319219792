var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: { title: _vm.$t("pendentes"), "collapse-action": "" },
        },
        [
          _c("div", { staticClass: "grid" }, [
            !_vm.showPendingQuestionnaires
              ? _c(
                  "label",
                  { staticClass: "font-bold justify-self-center text-warning" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "voce-ainda-nao-possui-nenhum-questionario-pendente"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.showPendingQuestionnaires
            ? _c("PendingQuestionnaires", {
                on: { "pendingCount-changed": _vm.pendingChanged },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vx-card",
        {
          ref: "results",
          staticClass: "mb-4",
          attrs: { title: _vm.$t("resultados"), "collapse-action": "" },
        },
        [
          _c("Grid", {
            ref: "answersGrid",
            attrs: {
              service: _vm.service,
              route_name: "questionnaires_answers",
              order_column: "finished_at",
              direction: "desc",
              fetchOnMounted: false,
            },
            scopedSlots: _vm._u([
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                      _vm._v("ID"),
                    ]),
                    _c(
                      "vs-th",
                      { key: "name", attrs: { "sort-key": "name" } },
                      [_vm._v(_vm._s(_vm.$t("Questionnaire")))]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "status",
                        attrs: { "sort-key": "score_released_at" },
                      },
                      [_vm._v(_vm._s(_vm.$t("situacao")))]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "finished_at",
                        attrs: { "sort-key": "finished_at" },
                      },
                      [_vm._v(_vm._s(_vm.$t("data-finalizacao-0")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridActions",
                fn: function () {
                  return [
                    _c("vs-th", { key: "actions", attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(_vm.$t("Actions"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: tr } },
                      [
                        _c("vs-td", { key: "id" }, [_vm._v(_vm._s(tr.id))]),
                        _c("vs-td", { key: "name" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                tr.content_questionnaire
                                  ? tr.content_questionnaire.name
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("vs-td", { key: "status" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                tr.content_questionnaire &&
                                  (tr.content_questionnaire.score_released_at ||
                                    tr.content_questionnaire.view_results)
                                  ? _vm.$t("resultado-liberacao-0")
                                  : _vm.$t("aguardando-liberacao-0")
                              ) +
                              " "
                          ),
                        ]),
                        _c("vs-td", { key: "finish_at" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                tr.finished_at !== null
                                  ? tr.finished_at_formatted
                                  : _vm.$t("nao-finalizado")
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "vs-td",
                          [
                            tr.finished_at === null
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t("continuar-questionario"),
                                      expression:
                                        "$t('continuar-questionario')",
                                    },
                                  ],
                                  attrs: {
                                    icon: "PlayIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 cursor-pointer stroke-current text-success",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.continueAnswer(
                                        tr.questionnaire_id
                                      )
                                    },
                                  },
                                })
                              : _c("feather-icon", {
                                  attrs: {
                                    icon: "EyeIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 cursor-pointer stroke-current text-primary",
                                    "v-tooltip": _vm.$t("visualizar-resultado"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showResults(
                                        tr.questionnaire_id,
                                        tr.id
                                      )
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }